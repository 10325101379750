@charset "UTF-8";
@use '../brand';

// Modal
//––––––––––––––––––––––––––––––––––––––––––––––––––

.modal {
    align-items: stretch;
    background-color: brand.$neutral-white;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    height: 60%;
    overflow: hidden;
    padding: 0.5rem;
    width: 60%;
    z-index: 10;

    &-body {
        display: flex;
        margin-bottom: 1rem;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: calc(100% - 11em);
    }

    &-container {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    &-footer {
        height: 3rem;
        text-align: right;
    }

    &-header {
        border-bottom: 1px solid lighten(brand.$border-color, 8.8%);
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        &-title {
            width: 75%;
            max-height: 8em;

            &-label {
                margin-bottom: 0.5rem;
            }
        }

        &-close {
            cursor: pointer;
            padding: 0.5rem;
            text-align: right;
            width: 25%;

            &-button {
                border: none;
                color: brand.$neutral-dark;
                padding: 0;

                :hover {
                    color: brand.$accent-color;
                }
            }

            &-icon {
                font-size: 2.5rem;
            }
        }
    }

    &-wrapper {
        margin-left: 1rem;
    }
}
