@charset "UTF-8";
@use '../brand';

// Tab
//––––––––––––––––––––––––––––––––––––––––––––––––––
// Inspired by https://www.youtube.com/watch?v=mBaMq1DphRs

.tabs {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &-wrapper {
    width: 100%;
    border-radius: 25px;
    box-shadow: 5px 10px 5px #ccc;
    padding: 20px 30px;
  }

  &-icon {
    margin-right: .25em;
    font-size: 1.5em;
  }

}

.tab {
  border-bottom: transparent .5em solid;
  cursor: pointer;
  margin-bottom: 0px;
  width: calc(100% /4);

  &.active {
    color: brand.$accent-color
  }

  &:hover {
    border-bottom: #aaa .5em solid;
    border-radius: 5px 5px;
    margin-bottom: -.5em;
  }

  &-indicator {
    background: brand.$accent-color;
    border-radius: 5px;
    height: .5em;
    left: 0;
    position: relative;
    transition: all 500ms ease-in-out;
    width: 25%;
  }

  &-content {
    margin-left: 1.25em;
    margin-right: 1.25em;
    min-height: 15em;
    padding-top: 1em;
  }

}