@charset "UTF-8";

/*
----------------------------------------------------------------------------
Font.
----------------------------------------------------------------------------
*/
$font-family-primary: 'Roboto Flex', sans-serif;
$font-family-accent: 'Roboto Flex', sans-serif;
$font-family-secondary: 'Roboto', sans-serif;

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-heavy: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-boldest: 900;

$font-size-100: .875rem;
$font-size-200: 1.0rem;
$font-size-300: 1.5rem;
$font-size-330: 1.8rem;
$font-size-400: 2.4rem;
$font-size-500: 3.0rem;
$font-size-600: 3.6rem;
$font-size-660: 4.0rem;
$font-size-700: 4.2rem;
$font-size-800: 5.0rem;
$font-size-900: 5.4rem;

$line-height-50: 0.5;
$line-height-100: 1.0;
$line-height-120: 1.2;
$line-height-125: 1.25;
$line-height-130: 1.3;
$line-height-135: 1.35;
$line-height-150: 1.5;
$line-height-160: 1.6;

$letter-spacing-100: -.1rem;
$letter-spacing-80: -.08rem;
$letter-spacing-50: -.05rem;
$letter-spacing-0: 0;

