@charset "UTF-8";
@use '../brand';

blockquote {
    font-family: brand.$font-family-secondary;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 50px;
    padding-left: 15px;
    border-left: 3px solid brand.$accent-color;
}
