@charset "UTF-8";
@use '../brand';

// HR
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
    margin-top: 3rem;
    margin-bottom: 3.5rem;
    border-width: 0;
    border-top: 1px solid brand.$neutral-light;
}
