@charset "UTF-8";
@use '../utilities';
@use '../brand';

.card-deck {
    margin: 5px;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 20px;
    gap: 20px;
    padding-top: 20px;

    @include utilities.media(">phone", "<=tablet") {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100px, auto);
    }

    @include utilities.media(">tablet", "<desktop") {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(100px, auto);
    }

    @include utilities.media(">=desktop") {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(100px, auto);
    }
}

.card {
    display: flex;
    flex-direction: column;
    color: #333;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 20px;
    overflow: hidden;

    img {
        width: 100%;
        object-fit: cover;
    }

    iframe {
        width: 100%;
        object-fit: cover;
    }

    &-image-wrapper {
        display: block;

        @include utilities.media(">phone", "<=tablet") {
            min-height: 200px;
            max-height: 200px;
        }

        @include utilities.media(">tablet", "<desktop") {
            min-height: 250px;
            max-height: 250px;
        }

        @include utilities.media(">=desktop") {
            min-height: 300px;
            max-height: 300px;
        }
    }

    &-header {
        font-size: 1.8em;
        font-weight: 600;
        letter-spacing: 2px;
        margin-bottom: 10px;

        i {
            font-size: 75%;
        }

        &-text {
            margin-right: 3%;
        }
    }

    &-body {
        padding: 30px;

        ul {
            list-style: none;
        }

        li {
            line-height: 2;
            border-bottom: brand.$neutral-light solid 1px;
        }

        li:last-child {
            border: 0;
        }
    }

    &-footer {
        margin-top: 10px;
        font-weight: 600;
        letter-spacing: 2px;

        &-icon {
            margin-right: 0.5em;           
        }

    }

    &-action {
        text-align: center;
        padding-top: 1em;

        a {
            width: 100%;
        }
    }
}
