@charset "UTF-8";
@use '../brand';
@use '../utilities';

/*
----------------------------------------------------------------------------
Base Classes.
----------------------------------------------------------------------------
*/

* {
  font-family: brand.$font-family-primary;
}

html, body {
  background-color: brand.$base-color;
  border: 0;
  color: brand.$text-color;
  font-weight: brand.$font-weight-regular;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

main {
  padding: 1rem;
}

/*
----------------------------------------------------------------------------
Spacing.
----------------------------------------------------------------------------
*/

button,
.button {
  margin-bottom: 1rem;
}

input,
textarea,
select,
fieldset {
  margin-bottom: 1.5rem;
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
  margin-bottom: 2.5rem;
}

/*
----------------------------------------------------------------------------
Typography.
----------------------------------------------------------------------------
*/

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: brand.$font-weight-light;
}

h1 { font-size: brand.$font-size-660; line-height: brand.$line-height-120; letter-spacing: brand.$letter-spacing-100; }
h2 { font-size: brand.$font-size-600; line-height: brand.$line-height-125; letter-spacing: brand.$letter-spacing-100; }
h3 { font-size: brand.$font-size-500; line-height: brand.$line-height-130; letter-spacing: brand.$letter-spacing-100; }
h4 { font-size: brand.$font-size-400; line-height: brand.$line-height-135; letter-spacing: brand.$letter-spacing-80; }
h5 { font-size: brand.$font-size-330; line-height: brand.$line-height-150; letter-spacing: brand.$letter-spacing-50; }
h6 { font-size: brand.$font-size-300; line-height: brand.$line-height-160; letter-spacing: brand.$letter-spacing-0; }

// Larger than tablet
@include utilities.media(">tablet") {
  h1 { font-size: brand.$font-size-800; }
  h2 { font-size: brand.$font-size-700; }
  h3 { font-size: brand.$font-size-600; }
  h4 { font-size: brand.$font-size-500; }
  h5 { font-size: brand.$font-size-400; }
  h6 { font-size: brand.$font-size-300; }
}

p {
  margin-top: 0;
}

.hidden { display: none; }

/*
----------------------------------------------------------------------------
Brand Utility Classes.
----------------------------------------------------------------------------
*/

.bg-color {background-color: brand.$base-color;}
.bg-color-accent { background-color: brand.$accent-color;}
.bg-color-base {background-color: brand.$base-color;}
.bg-color-primary { background-color: brand.$primary-color;}
.bg-color-secondary { background-color: brand.$secondary-color;}

.bg-color-neutral { background-color: brand.$neutral;}
.bg-color-neutral-black { background-color: brand.$neutral-black;}
.bg-color-neutral-dark { background-color: brand.$neutral-dark;}
.bg-color-neutral-light { background-color: brand.$neutral-light;}
.bg-color-neutral-lighter { background-color: brand.$neutral-lighter;}
.bg-color-neutral-white { background-color: brand.$neutral-white;}

.font-accent { font-family: brand.$font-family-accent;}
.font-primary { font-family: brand.$font-family-primary;}
.font-secondary { font-family: brand.$font-family-secondary;}

.font-color-accent {color: brand.$accent-color;}
.font-color-error {color: brand.$error-color;}
.font-color-link {color: brand.$link-color; }
.font-color-primary {color: brand.$primary-color;}
.font-color-secondary {color: brand.$secondary-color;}
.font-color-text { color: brand.$text-color;}

.form-color { color: brand.$form-color;}
.form-control-color {color: brand.$form-control-color;}
.form-control-disabled-color {color: brand.$form-control-disabled-color;}

.border-color { border-color: brand.$border-color;}

.fw-bold { font-weight: brand.$font-weight-bold;}
.fw-light { font-weight: brand.$font-weight-light;}
.fw-medium { font-weight: brand.$font-weight-medium;}
.fw-regular { font-weight: brand.$font-weight-regular;}

.fs-100 { font-size: brand.$font-size-100;}
.fs-200 { font-size: brand.$font-size-200;}
.fs-300 { font-size: brand.$font-size-300;}
.fs-330 { font-size: brand.$font-size-330;}
.fs-400 { font-size: brand.$font-size-400;}
.fs-500 { font-size: brand.$font-size-500;}
.fs-600 { font-size: brand.$font-size-600;}
.fs-660 { font-size: brand.$font-size-660;}
.fs-700 { font-size: brand.$font-size-700;}
.fs-800 { font-size: brand.$font-size-800;}
.fs-900 { font-size: brand.$font-size-900;}
