@charset "UTF-8";
@use '../website/styles/brand';

footer {
    padding: .5em;
    color: brand.$secondary-color;
    background-color: brand.$primary-color;

}

footer .copyright-text {
    vertical-align: middle;
}