@charset "UTF-8";
@use '../brand';

// Label
//––––––––––––––––––––––––––––––––––––––––––––––––––

label {
    display: block;
    margin-bottom: .5rem;
    font-weight: brand.$font-weight-heavy;
}

label > .label-body {
    display: inline-block;
    margin-left: .5rem;
    font-weight: normal;
}
