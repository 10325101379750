@charset "UTF-8";
@use '../utilities';
@use '../brand';

//––––––––––––––––––––––––––––––––––––––––––––––––––
// Forms
//––––––––––––––––––––––––––––––––––––––––––––––––––

form {
    display: grid;
}

//––––––––––––––––––––––––––––––––––––––––––––––––––
// Form Controls
//––––––––––––––––––––––––––––––––––––––––––––––––––

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea,
select {
    height: 38px;
    padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: brand.$neutral-light;
    border: 1px solid lighten(brand.$border-color, 8.8%);
    border-radius: utilities.$global-radius;
    box-shadow: none;
    box-sizing: border-box;
}

// Removes awkward default styles on some inputs for iOS
input {
    &[type="email"],
    &[type="number"],
    &[type="search"],
    &[type="text"],
    &[type="tel"],
    &[type="url"],
    &[type="password"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px;
}

input {
    &[type="email"]:focus,
    &[type="number"]:focus,
    &[type="search"]:focus,
    &[type="text"]:focus,
    &[type="tel"]:focus,
    &[type="url"]:focus,
    &[type="password"]:focus {
        border: 1px solid brand.$primary-color;
        outline: 0;
    }
}

textarea:focus,
select:focus {
    border: 1px solid brand.$primary-color;
    outline: 0;
}

//––––––––––––––––––––––––––––––––––––––––––––––––––
// Utility Classes
//––––––––––––––––––––––––––––––––––––––––––––––––––

.form-control {
    font-family: system-ui, sans-serif;
    font-family: brand.$font-family-primary;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

.form-control + .form-control {
    margin-top: 1em;
}

.form-control-disabled {
    color: brand.$form-control-disabled-color;
    cursor: not-allowed;
}
