@charset "UTF-8";
@use '../brand';

.panel {
    -webkit-backdrop-filter: blur(8px);
    /* Safari 9+ */
    backdrop-filter: blur(8px);
    /* Chrome and Opera */
    background-color: rgb(228 228 228 / 25%);
    border-radius: 15px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 25%);
    height: calc(100vh * .5);
    left: 1rem;
    padding: 1em;
    position: absolute;
    top: 1em;
    width: calc(100vw * .66);

    &-header {
        border-bottom: brand.$border-color 2px solid;
        font-size: 2.0em;
        height: 2.5em;
        padding-bottom: 0.25em;
        padding-top: 0.25em;
    }

    &-body {
        min-height: calc(100% - 6.75rem);
        padding-bottom: 0.5em;
        padding-top: 0.5em;
    }

    &-footer {
        align-items: center;
        border-top: brand.$border-color 2px solid;
        display: flex;
        height: 2.5em;
        justify-content: right;
        padding-bottom: 0.5em;
        padding-top: 0.5em;

        &-link {
            text-decoration: none;
            color: #000;
        }

        &-icon {
            margin-left: 0.5em;
        }

    }
}