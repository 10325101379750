// Inspired from https://piccalil.li/blog/a-modern-css-reset/,
//               https://www.joshwcomeau.com/css/custom-css-reset/#digit-root-stacking-context

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    font: inherit
}

ul[role="list"],
ol[role="list"] {
    list-style: none;
}

html::focus-within {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
}

body {
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture,
svg {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}