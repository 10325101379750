@charset "UTF-8";
@use '../utilities';
@use '../brand';

.progress-bar {
    height: 2.0em;
    background-color: brand.$neutral-light;
    position: relative;
    border-radius: 20px;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 800;
    text-transform: uppercase !important;
    margin: 5px;
    color: brand.$accent-color;

    &:before {
        content: attr(data-label);
        font-size: 1.0em;
        position: absolute;
        text-align: center;
        vertical-align: middle;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .value {
        background-color: brand.$primary-color;
        font-family: brand.$font-family-primary;
        display: inline-block;
        height: 100%;
        border-radius: 20px;
    }
}
