@charset "UTF-8";
@use '../brand';

nav {
    overflow: hidden;
    background-color: brand.$neutral-dark;
    padding-left: 1em;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 0;
    }

    li.active {
        background-color: brand.$neutral-light;
    }

    a {
        float: left;
        color: brand.$neutral-light;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
        vertical-align: middle;
    }

    a:hover {
        background-color: brand.$neutral;
        color: black;
    }

    a.active {
        color: white;
    }
}
