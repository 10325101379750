*, :before, :after {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html::focus-within {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
}

body {
  text-rendering: optimizespeed;
  -webkit-font-smoothing: antialiased;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
}

.ribbon-container {
  min-height: 30px;
}

nav {
  min-height: 60px;
}

header {
  min-height: 100px;
}

main {
  min-height: calc(100vh - 240px - 3.95em);
}

footer {
  min-height: 50px;
}

.gridjs-head button, .gridjs-footer button {
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.gridjs-temp {
  position: relative;
}

.gridjs-head {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 1px;
}

.gridjs-head:after {
  content: "";
  clear: both;
  display: block;
}

.gridjs-head:empty {
  border: none;
  padding: 0;
}

.gridjs-container {
  color: #000;
  z-index: 0;
  padding: 2px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gridjs-footer {
  width: 100%;
  z-index: 5;
  background-color: #fff;
  border-color: #e5e7eb;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-radius: 0 0 8px 8px;
  padding: 12px 24px;
  display: block;
  position: relative;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #00000042;
}

.gridjs-footer:empty {
  border: none;
  padding: 0;
}

input.gridjs-input {
  appearance: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 5px;
  outline: none;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 1.45;
}

input.gridjs-input:focus {
  border-color: #9bc2f7;
  box-shadow: 0 0 0 3px #95bdf380;
}

.gridjs-pagination {
  color: #3d4044;
}

.gridjs-pagination:after {
  content: "";
  clear: both;
  display: block;
}

.gridjs-pagination .gridjs-summary {
  float: left;
  margin-top: 5px;
}

.gridjs-pagination .gridjs-pages {
  float: right;
}

.gridjs-pagination .gridjs-pages button {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-right: none;
  outline: none;
  padding: 5px 14px;
}

.gridjs-pagination .gridjs-pages button:focus {
  border-right: 1px solid #d2d6dc;
  margin-right: -1px;
  position: relative;
  box-shadow: 0 0 0 2px #95bdf380;
}

.gridjs-pagination .gridjs-pages button:hover {
  color: #3c4257;
  background-color: #f7f7f7;
  outline: none;
}

.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button[disabled], .gridjs-pagination .gridjs-pages button:hover:disabled {
  cursor: default;
  color: #6b7280;
  background-color: #fff;
}

.gridjs-pagination .gridjs-pages button.gridjs-spread {
  cursor: default;
  box-shadow: none;
  background-color: #fff;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #f7f7f7;
  font-weight: bold;
}

.gridjs-pagination .gridjs-pages button:last-child {
  border-right: 1px solid #d2d6dc;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.gridjs-pagination .gridjs-pages button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.gridjs-pagination .gridjs-pages button:last-child:focus {
  margin-right: 0;
}

button.gridjs-sort {
  float: right;
  height: 24px;
  width: 13px;
  cursor: pointer;
  background-color: #0000;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

button.gridjs-sort-neutral {
  opacity: .3;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSI0MDEuOTk4cHgiIGhlaWdodD0iNDAxLjk5OHB4IiB2aWV3Qm94PSIwIDAgNDAxLjk5OCA0MDEuOTk4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MDEuOTk4IDQwMS45OTg7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik03My4wOTIsMTY0LjQ1MmgyNTUuODEzYzQuOTQ5LDAsOS4yMzMtMS44MDcsMTIuODQ4LTUuNDI0YzMuNjEzLTMuNjE2LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0NwoJCQljMC00Ljk0OS0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2LDUuNDI0QzIxMC4yMzIsMS44MTIsMjA1Ljk1MSwwLDIwMC45OTksMHMtOS4yMzMsMS44MTItMTIuODUsNS40MjRMNjAuMjQyLDEzMy4zMzEKCQkJYy0zLjYxNywzLjYxNy01LjQyNCw3LjkwMS01LjQyNCwxMi44NWMwLDQuOTQ4LDEuODA3LDkuMjMxLDUuNDI0LDEyLjg0N0M2My44NjMsMTYyLjY0NSw2OC4xNDQsMTY0LjQ1Miw3My4wOTIsMTY0LjQ1MnoiLz4KCQk8cGF0aCBkPSJNMzI4LjkwNSwyMzcuNTQ5SDczLjA5MmMtNC45NTIsMC05LjIzMywxLjgwOC0xMi44NSw1LjQyMWMtMy42MTcsMy42MTctNS40MjQsNy44OTgtNS40MjQsMTIuODQ3CgkJCWMwLDQuOTQ5LDEuODA3LDkuMjMzLDUuNDI0LDEyLjg0OEwxODguMTQ5LDM5Ni41N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhsMTI3LjkwNy0xMjcuOTA2CgkJCWMzLjYxMy0zLjYxNCw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDhjMC00Ljk0OC0xLjgxMy05LjIyOS01LjQyNy0xMi44NDdDMzM4LjEzOSwyMzkuMzUzLDMzMy44NTQsMjM3LjU0OSwzMjguOTA1LDIzNy41NDl6Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: center;
}

button.gridjs-sort-asc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyOTIuMzYycHgiIGhlaWdodD0iMjkyLjM2MXB4IiB2aWV3Qm94PSIwIDAgMjkyLjM2MiAyOTIuMzYxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyOTIuMzYyIDI5Mi4zNjE7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik0yODYuOTM1LDE5Ny4yODdMMTU5LjAyOCw2OS4zODFjLTMuNjEzLTMuNjE3LTcuODk1LTUuNDI0LTEyLjg0Ny01LjQyNHMtOS4yMzMsMS44MDctMTIuODUsNS40MjRMNS40MjQsMTk3LjI4NwoJCUMxLjgwNywyMDAuOTA0LDAsMjA1LjE4NiwwLDIxMC4xMzRzMS44MDcsOS4yMzMsNS40MjQsMTIuODQ3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI1LDEyLjg1LDUuNDI1aDI1NS44MTMKCQljNC45NDksMCw5LjIzMy0xLjgwOCwxMi44NDgtNS40MjVjMy42MTMtMy42MTMsNS40MjctNy44OTgsNS40MjctMTIuODQ3UzI5MC41NDgsMjAwLjkwNCwyODYuOTM1LDE5Ny4yODd6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: 35%;
  background-size: 10px;
}

button.gridjs-sort-desc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyOTIuMzYycHgiIGhlaWdodD0iMjkyLjM2MnB4IiB2aWV3Qm94PSIwIDAgMjkyLjM2MiAyOTIuMzYyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyOTIuMzYyIDI5Mi4zNjI7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik0yODYuOTM1LDY5LjM3N2MtMy42MTQtMy42MTctNy44OTgtNS40MjQtMTIuODQ4LTUuNDI0SDE4LjI3NGMtNC45NTIsMC05LjIzMywxLjgwNy0xMi44NSw1LjQyNAoJCUMxLjgwNyw3Mi45OTgsMCw3Ny4yNzksMCw4Mi4yMjhjMCw0Ljk0OCwxLjgwNyw5LjIyOSw1LjQyNCwxMi44NDdsMTI3LjkwNywxMjcuOTA3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI4LDEyLjg1LDUuNDI4CgkJczkuMjMzLTEuODExLDEyLjg0Ny01LjQyOEwyODYuOTM1LDk1LjA3NGMzLjYxMy0zLjYxNyw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDdDMjkyLjM2Miw3Ny4yNzksMjkwLjU0OCw3Mi45OTgsMjg2LjkzNSw2OS4zNzd6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: 65%;
  background-size: 10px;
}

button.gridjs-sort:focus {
  outline: none;
}

table.gridjs-table {
  max-width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  display: table;
  overflow: auto;
}

.gridjs-tbody {
  background-color: #fff;
}

td.gridjs-td {
  box-sizing: content-box;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  padding: 12px 24px;
}

td.gridjs-td:first-child {
  border-left: none;
}

td.gridjs-td:last-child {
  border-right: none;
}

td.gridjs-message {
  text-align: center;
}

th.gridjs-th {
  color: #6b7280;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-top: none;
  outline: none;
  padding: 14px 24px;
  position: relative;
}

th.gridjs-th .gridjs-th-content {
  text-overflow: ellipsis;
  width: 100%;
  float: left;
  overflow: hidden;
}

th.gridjs-th-sort {
  cursor: pointer;
}

th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 15px);
}

th.gridjs-th-sort:hover, th.gridjs-th-sort:focus {
  background-color: #e5e7eb;
}

th.gridjs-th-fixed {
  position: sticky;
  box-shadow: 0 1px #e5e7eb;
}

@supports (-moz-appearance: none) {
  th.gridjs-th-fixed {
    box-shadow: 0 0 0 1px #e5e7eb;
  }
}

th.gridjs-th:first-child {
  border-left: none;
}

th.gridjs-th:last-child {
  border-right: none;
}

.gridjs-tr {
  border: none;
}

.gridjs-tr-selected td {
  background-color: #ebf5ff;
}

.gridjs-tr:last-child td {
  border-bottom: 0;
}

.gridjs *, .gridjs :after, .gridjs :before {
  box-sizing: border-box;
}

.gridjs-wrapper {
  z-index: 1;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: #e5e7eb;
  border-top-width: 1px;
  border-radius: 8px 8px 0 0;
  display: block;
  position: relative;
  overflow: auto;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #00000042;
}

.gridjs-wrapper:nth-last-of-type(2) {
  border-bottom-width: 1px;
  border-radius: 8px;
}

.gridjs-search {
  float: left;
}

.gridjs-search-input {
  width: 250px;
}

.gridjs-loading-bar {
  z-index: 10;
  opacity: .5;
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.gridjs-loading-bar:after {
  content: "";
  background-image: linear-gradient(90deg, #ccc0 0, #ccc3 20%, #cccccc80 60%, #ccc0);
  animation: shimmer 2s infinite;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gridjs-td .gridjs-checkbox {
  cursor: pointer;
  margin: auto;
  display: block;
}

.gridjs-resizable {
  width: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.gridjs-resizable:hover {
  cursor: ew-resize;
  background-color: #9bc2f7;
}

* {
  font-family: Roboto Flex, sans-serif;
}

html, body {
  color: #1b1313;
  background-color: #d1dce6;
  border: 0;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.6;
}

main {
  padding: 1rem;
}

button, .button {
  margin-bottom: 1rem;
}

input, textarea, select, fieldset {
  margin-bottom: 1.5rem;
}

pre, blockquote, dl, figure, table, p, ul, ol, form {
  margin-bottom: 2.5rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}

h1 {
  letter-spacing: -.1rem;
  font-size: 4rem;
  line-height: 1.2;
}

h2 {
  letter-spacing: -.1rem;
  font-size: 3.6rem;
  line-height: 1.25;
}

h3 {
  letter-spacing: -.1rem;
  font-size: 3rem;
  line-height: 1.3;
}

h4 {
  letter-spacing: -.08rem;
  font-size: 2.4rem;
  line-height: 1.35;
}

h5 {
  letter-spacing: -.05rem;
  font-size: 1.8rem;
  line-height: 1.5;
}

h6 {
  letter-spacing: 0;
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (min-width: 769px) {
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4.2rem;
  }

  h3 {
    font-size: 3.6rem;
  }

  h4 {
    font-size: 3rem;
  }

  h5 {
    font-size: 2.4rem;
  }

  h6 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
}

.hidden {
  display: none;
}

.bg-color {
  background-color: #d1dce6;
}

.bg-color-accent {
  background-color: #d08362;
}

.bg-color-base {
  background-color: #d1dce6;
}

.bg-color-primary {
  background-color: #80625b;
}

.bg-color-secondary {
  background-color: #c7b6ad;
}

.bg-color-neutral {
  background-color: #c1bdbd;
}

.bg-color-neutral-black {
  background-color: #000;
}

.bg-color-neutral-dark {
  background-color: #1b1313;
}

.bg-color-neutral-light {
  background-color: #d1dce6;
}

.bg-color-neutral-lighter {
  background-color: #d7dfe5;
}

.bg-color-neutral-white {
  background-color: #fff;
}

.font-accent, .font-primary {
  font-family: Roboto Flex, sans-serif;
}

.font-secondary {
  font-family: Roboto, sans-serif;
}

.font-color-accent {
  color: #d08362;
}

.font-color-error {
  color: #e61a4d;
}

.font-color-link {
  color: #5c95a3;
}

.font-color-primary {
  color: #80625b;
}

.font-color-secondary {
  color: #c7b6ad;
}

.font-color-text {
  color: #1b1313;
}

.form-color {
  color: #d1dce6;
}

.form-control-color {
  color: #80625b;
}

.form-control-disabled-color {
  color: #c1bdbd;
}

.border-color {
  border-color: #1b1313;
}

.fw-bold {
  font-weight: 700;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-regular {
  font-weight: 400;
}

.fs-100 {
  font-size: .875rem;
}

.fs-200 {
  font-size: 1rem;
}

.fs-300 {
  font-size: 1.5rem;
}

.fs-330 {
  font-size: 1.8rem;
}

.fs-400 {
  font-size: 2.4rem;
}

.fs-500 {
  font-size: 3rem;
}

.fs-600 {
  font-size: 3.6rem;
}

.fs-660 {
  font-size: 4rem;
}

.fs-700 {
  font-size: 4.2rem;
}

.fs-800 {
  font-size: 5rem;
}

.fs-900 {
  font-size: 5.4rem;
}

blockquote {
  border-left: 3px solid #d08362;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
  padding-left: 15px;
  font-family: Roboto, sans-serif;
  font-style: italic;
}

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  height: 38px;
  color: #d08362;
  text-align: center;
  letter-spacing: .1rem;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #0000;
  border: 1px solid #1b1313;
  border-radius: 4px;
  padding: 0 30px;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  text-decoration: none;
  display: inline-block;
}

.button:hover, button:hover {
  color: #1b1313;
  border-color: #fff;
  outline: 0;
}

input[type="submit"]:hover, input[type="reset"]:hover, input[type="button"]:hover, .button:focus, button:focus {
  color: #1b1313;
  border-color: #7e5a5a;
  outline: 0;
}

input[type="submit"]:focus, input[type="reset"]:focus, input[type="button"]:focus {
  color: #d08362;
  border-color: #7e5a5a;
  outline: 0;
}

.button.button-primary, button.button-primary, input[type="submit"].button-primary, input[type="reset"].button-primary, input[type="button"].button-primary {
  color: #c7b6ad;
  background-color: #80625b;
  border-color: #80625b;
}

.button.button-primary:hover, button.button-primary:hover, input[type="submit"].button-primary:hover, input[type="reset"].button-primary:hover, input[type="button"].button-primary:hover {
  color: #80625b;
  background-color: #d08362;
  border-color: #d08362;
}

.button.button-primary:focus, button.button-primary:focus, input[type="submit"].button-primary:focus, input[type="reset"].button-primary:focus, input[type="button"].button-primary:focus {
  color: #c7b6ad;
  background-color: #80625b;
  border-color: #d08362;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"] {
  height: 38px;
  box-shadow: none;
  box-sizing: border-box;
  background-color: #c7b6ad;
  border: 1px solid #352626;
  border-radius: 4px;
  padding: 6px 10px;
}

.card-deck {
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(100px, auto);
  align-content: center;
  gap: 20px;
  margin: 5px;
  padding-top: 20px;
  display: grid;
}

@media (min-width: 577px) and (max-width: 768px) {
  .card-deck {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .card-deck {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}

@media (min-width: 992px) {
  .card-deck {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }
}

.card {
  color: #333;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 8px #0003;
}

.card img, .card iframe {
  width: 100%;
  object-fit: cover;
}

.card-image-wrapper {
  display: block;
}

@media (min-width: 577px) and (max-width: 768px) {
  .card-image-wrapper {
    min-height: 200px;
    max-height: 200px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .card-image-wrapper {
    min-height: 250px;
    max-height: 250px;
  }
}

@media (min-width: 992px) {
  .card-image-wrapper {
    min-height: 300px;
    max-height: 300px;
  }
}

.card-header {
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-size: 1.8em;
  font-weight: 600;
}

.card-header i {
  font-size: 75%;
}

.card-header-text {
  margin-right: 3%;
}

.card-body {
  padding: 30px;
}

.card-body ul {
  list-style: none;
}

.card-body li {
  border-bottom: 1px solid #d1dce6;
  line-height: 2;
}

.card-body li:last-child {
  border: 0;
}

.card-footer {
  letter-spacing: 2px;
  margin-top: 10px;
  font-weight: 600;
}

.card-footer-icon {
  margin-right: .5em;
}

.card-action {
  text-align: center;
  padding-top: 1em;
}

.card-action a {
  width: 100%;
}

input[type="checkbox"] {
  appearance: none;
  font: inherit;
  color: #80625b;
  width: 1.15em;
  height: 1.15em;
  background-color: #d1dce6;
  border: .15em solid #80625b;
  border-radius: .15em;
  place-content: center;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

input[type="checkbox"]:before {
  content: "";
  width: .65em;
  height: .65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform-origin: 0 100%;
  background-color: CanvasText;
  transition: transform .12s ease-in-out;
  transform: scale(0);
  box-shadow: inset 1em 1em #80625b;
}

input[type="checkbox"]:checked:before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline-offset: max(2px, .15em);
  outline: max(2px, .15em) solid #80625b;
}

input[type="checkbox"]:disabled {
  color: #c1bdbd;
  cursor: not-allowed;
}

code {
  white-space: nowrap;
  background: #e6ecf1;
  border: 1px solid #d1dce6;
  border-radius: 4px;
  margin: 0 .2rem;
  padding: .2rem .5rem;
  font-size: 90%;
}

pre > code {
  white-space: pre;
  padding: 1rem 1.5rem;
  display: block;
}

fieldset {
  min-inline-size: min-content;
  border: 2px groove #1b1313;
  border-image: initial;
  margin-inline: 2px;
  padding-block: .35em .625em;
  padding-inline: .75em;
  display: block;
}

legend {
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  margin-bottom: .5rem;
  padding-inline: 2px;
  font-weight: 600;
  display: block;
}

form {
  display: grid;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea, select {
  height: 38px;
  box-shadow: none;
  box-sizing: border-box;
  background-color: #d1dce6;
  border: 1px solid #352626;
  border-radius: 4px;
  padding: 6px 10px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"] {
  appearance: none;
}

textarea {
  appearance: none;
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="text"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
  border: 1px solid #80625b;
  outline: 0;
}

.form-control {
  grid-template-columns: 1em auto;
  gap: .5em;
  font-family: Roboto Flex, sans-serif;
  display: grid;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control-disabled {
  color: #c1bdbd;
  cursor: not-allowed;
}

header {
  color: #80625b;
  padding: 2.5em 1em 1.5em;
}

header h1 {
  font-size: 3.5em;
}

hr {
  border-width: 1px 0 0;
  border-top-style: solid;
  border-top-color: #d1dce6;
  margin-top: 3rem;
  margin-bottom: 3.5rem;
}

label {
  margin-bottom: .5rem;
  font-weight: 600;
  display: block;
}

label > .label-body {
  margin-left: .5rem;
  font-weight: normal;
  display: inline-block;
}

a {
  color: #5c95a3;
}

a:hover {
  color: #538693;
}

ul {
  list-style: circle inside;
}

ol {
  margin-top: 0;
  padding-left: 0;
  list-style: decimal inside;
}

ul {
  margin-top: 0;
  padding-left: 0;
}

ul ul, ul ol, ol ol, ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}

li {
  margin-bottom: 1rem;
}

.modal {
  height: 60%;
  width: 60%;
  z-index: 10;
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  align-items: stretch;
  padding: .5rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.modal-body {
  min-height: calc(100% - 11em);
  margin-bottom: 1rem;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-container {
  height: 100vh;
  width: 100%;
  z-index: 10;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-footer {
  height: 3rem;
  text-align: right;
}

.modal-header {
  border-bottom: 1px solid #352626;
  flex-direction: row;
  margin-bottom: 1rem;
  display: flex;
}

.modal-header-title {
  width: 75%;
  max-height: 8em;
}

.modal-header-title-label {
  margin-bottom: .5rem;
}

.modal-header-close {
  cursor: pointer;
  text-align: right;
  width: 25%;
  padding: .5rem;
}

.modal-header-close-button {
  color: #1b1313;
  border: none;
  padding: 0;
}

.modal-header-close-button :hover {
  color: #d08362;
}

.modal-header-close-icon {
  font-size: 2.5rem;
}

.modal-wrapper {
  margin-left: 1rem;
}

nav {
  background-color: #1b1313;
  padding-left: 1em;
  overflow: hidden;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

nav li {
  margin-bottom: 0;
}

nav li.active {
  background-color: #d1dce6;
}

nav a {
  float: left;
  color: #d1dce6;
  text-align: center;
  vertical-align: middle;
  padding: 14px 16px;
  font-size: 17px;
  text-decoration: none;
}

nav a:hover {
  color: #000;
  background-color: #c1bdbd;
}

nav a.active {
  color: #fff;
}

.panel {
  -webkit-backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  height: 50vh;
  width: 66vw;
  background-color: #e4e4e440;
  border-radius: 15px;
  padding: 1em;
  position: absolute;
  top: 1em;
  left: 1rem;
  box-shadow: 0 10px 15px 10px #00000040;
}

.panel-header {
  height: 2.5em;
  border-bottom: 2px solid #1b1313;
  padding-top: .25em;
  padding-bottom: .25em;
  font-size: 2em;
}

.panel-body {
  min-height: calc(100% - 6.75rem);
  padding-top: .5em;
  padding-bottom: .5em;
}

.panel-footer {
  height: 2.5em;
  border-top: 2px solid #1b1313;
  justify-content: right;
  align-items: center;
  padding-top: .5em;
  padding-bottom: .5em;
  display: flex;
}

.panel-footer-link {
  color: #000;
  text-decoration: none;
}

.panel-footer-icon {
  margin-left: .5em;
}

.progress-bar {
  height: 2em;
  letter-spacing: 1px;
  color: #d08362;
  background-color: #d1dce6;
  border-radius: 20px;
  margin: 5px;
  font-size: 20px;
  font-weight: 800;
  position: relative;
  text-transform: uppercase !important;
}

.progress-bar:before {
  content: attr(data-label);
  text-align: center;
  vertical-align: middle;
  font-size: 1em;
  position: absolute;
  inset: 0;
}

.progress-bar .value {
  height: 100%;
  background-color: #80625b;
  border-radius: 20px;
  font-family: Roboto Flex, sans-serif;
  display: inline-block;
}

input[type="radio"] {
  appearance: none;
  font: inherit;
  color: #80625b;
  width: 1.15em;
  height: 1.15em;
  background-color: #d1dce6;
  border: .15em solid #80625b;
  border-radius: 50%;
  place-content: center;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

input[type="radio"]:before {
  content: "";
  width: .65em;
  height: .65em;
  background-color: CanvasText;
  border-radius: 50%;
  transition: transform .12s ease-in-out;
  transform: scale(0);
  box-shadow: inset 1em 1em #80625b;
}

input[type="radio"]:checked:before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline-offset: max(2px, .15em);
  outline: max(2px, .15em) solid #80625b;
}

.tabs {
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.tabs-wrapper {
  width: 100%;
  border-radius: 25px;
  padding: 20px 30px;
  box-shadow: 5px 10px 5px #ccc;
}

.tabs-icon {
  margin-right: .25em;
  font-size: 1.5em;
}

.tab {
  cursor: pointer;
  width: 25%;
  border-bottom: .5em solid #0000;
  margin-bottom: 0;
}

.tab.active {
  color: #d08362;
}

.tab:hover {
  border-bottom: .5em solid #aaa;
  border-radius: 5px;
  margin-bottom: -.5em;
}

.tab-indicator {
  height: .5em;
  width: 25%;
  background: #d08362;
  border-radius: 5px;
  transition: all .5s ease-in-out;
  position: relative;
  left: 0;
}

.tab-content {
  min-height: 15em;
  margin-left: 1.25em;
  margin-right: 1.25em;
  padding-top: 1em;
}

table {
  border-collapse: collapse;
  min-width: 400px;
  margin: 25px 0;
  font-family: sans-serif;
  font-size: .9em;
  box-shadow: 0 0 20px #00000026;
}

thead tr {
  color: #c7b6ad;
  text-align: left;
  background-color: #80625b;
}

thead tr th:last-of-type {
  text-align: right;
}

th, td {
  padding: 12px 15px;
}

tbody {
  background-color: #fff;
}

tbody tr {
  border-bottom: 1px solid #c1bdbd;
}

tbody tr:nth-of-type(2n) {
  background: #d7dfe5;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #1b1313;
}

tbody tr td:last-of-type {
  text-align: right;
}

tbody tr.active-row {
  color: #d08362;
  font-weight: bold;
}

.ribbon {
  text-align: right;
  min-height: 30px;
  background-color: #c7b6ad;
}

footer {
  color: #c7b6ad;
  background-color: #80625b;
  padding: .5em;
}

footer .copyright-text {
  vertical-align: middle;
}

/*# sourceMappingURL=index.bacefb68.css.map */
