@charset "UTF-8";
@use '../brand';

// Table
//––––––––––––––––––––––––––––––––––––––––––––––––––

table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

thead tr {
  background-color: brand.$primary-color;
  color: brand.$secondary-color;
  text-align: left;
}

thead tr th:last-of-type {
  text-align: right;
}

th,
td {
  padding: 12px 15px;
}

tbody {
  background-color: brand.$neutral-white;
}

tbody tr {
  border-bottom: 1px solid brand.$neutral;
}

tbody tr:nth-of-type(even) {
  background: brand.$neutral-lighter;
}

tbody tr:last-of-type {
  border-bottom: 2px solid brand.$border-color;
}

tbody tr td:last-of-type {
  text-align: right;
}

tbody tr.active-row {
  font-weight: bold;
  color: brand.$accent-color;
}