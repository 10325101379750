@charset "UTF-8";
@use '../utilities';
@use '../brand';

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
    color: brand.$link-color;

    &:hover {
        color: darken(brand.$link-color, 5%);
    }
}
