@charset "UTF-8";
@use '../utilities';

* { box-sizing: border-box; }

body {
    padding: 0; margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.ribbon-container {
    min-height: utilities.$global-ribbon-height;
}

nav {
    min-height: utilities.$global-nav-height;
}

header {
    min-height: utilities.$global-header-height;
}

main {
    min-height: calc(100vh - utilities.$global-ribbon-height - utilities.$global-nav-height - utilities.$global-header-height - utilities.$global-footer-height - 3.95em);
}

footer {
    min-height: utilities.$global-footer-height;
}