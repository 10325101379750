@charset "UTF-8";
@use '../brand';

header {
    color: brand.$primary-color;
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 2.5em;
    padding-bottom: 1.5em;

    h1 {
        font-size: 3.5em;
    }
}
