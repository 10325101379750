@charset "UTF-8";

// http://www.color-blindness.com/color-name-hue/

/*
----------------------------------------------------------------------------
Color Maps.
----------------------------------------------------------------------------
*/

$dune: (
    black:hsl(0, 0%, 0%),
    burning-sand: hsl(18, 54%, 60%),
    cold-turkey: hsl(21, 19%, 73%),
    haki: hsl(192, 28%, 50%),
    oyster-bay: hsl(209, 30%, 86%),
    russett: hsl(12, 17%, 43%),
    silver: hsl(0, 3%, 75%),
    white:hsl(0, 0%, 100%),
    wood-bark: hsl(0, 17%, 9%),
    solitude: hsl(206, 21%, 87%)
);

/*
----------------------------------------------------------------------------
Brand.
----------------------------------------------------------------------------
*/

$primary-color: map-get($dune,russett); 
$secondary-color: map-get($dune,cold-turkey);
$accent-color: map-get($dune, burning-sand); 

/*
----------------------------------------------------------------------------
Neutral palette.
----------------------------------------------------------------------------
*/
$neutral-black:map-get($dune,black);
$neutral-dark: map-get($dune,wood-bark);
$neutral-light:map-get($dune,oyster-bay);
$neutral-lighter:map-get($dune,solitude);
$neutral-white:map-get($dune,white);
$neutral: map-get($dune,silver);

/*
----------------------------------------------------------------------------
Accents & states.
----------------------------------------------------------------------------
*/
$base-color: map-get($dune,oyster-bay );
$border-color: map-get($dune,wood-bark );
$error-color: hsl(345, 80%, 50%);
$form-color: map-get($dune,oyster-bay );
$form-control-color: map-get($dune,russett);
$form-control-disabled-color: map-get($dune,silver);
$link-color: map-get($dune,haki);
$text-color: map-get($dune,wood-bark);