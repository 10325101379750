@charset "UTF-8";
@use '../utilities';
@use '../brand';

// @link https://moderncss.dev/pure-css-custom-styled-radio-buttons/

input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: brand.$form-color;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: brand.$form-control-color;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid brand.$form-control-color;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em brand.$primary-color;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid brand.$form-control-color;
    ;
    outline-offset: max(2px, 0.15em);
}
