@charset "UTF-8";
@use '../utilities';
@use '../brand';

// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button {
    display: inline-block;
    height: 38px;
    padding: 0 30px;
    color: brand.$accent-color;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: utilities.$global-radius;
    border: 1px solid brand.$border-color;
    cursor: pointer;
    box-sizing: border-box;
}

input {
    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
        display: inline-block;
        height: 38px;
        padding: 0 30px;
        color: brand.$accent-color;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: .1rem;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border-radius: utilities.$global-radius;
        border: 1px solid brand.$border-color;
        cursor: pointer;
        box-sizing: border-box;
    }
}

.button:hover,
button:hover {
    color: brand.$neutral-dark;
    border-color: lighten(brand.$neutral-light, 33.3%);
    outline: 0;
}

input {
    &[type="submit"]:hover,
    &[type="reset"]:hover,
    &[type="button"]:hover {
        color: brand.$neutral-dark;
        border-color: lighten(brand.$neutral-dark, 33.3%);
        outline: 0;
    }
}

.button:focus,
button:focus {
    color: brand.$neutral-dark;
    border-color: lighten(brand.$neutral-dark, 33.3%);
    outline: 0;
}

input {
    &[type="submit"]:focus,
    &[type="reset"]:focus,
    &[type="button"]:focus {
        color: brand.$accent-color;
        border-color: lighten(brand.$neutral-dark, 33.3%);
        outline: 0;
    }
}

.button.button-primary,
button.button-primary {
    color: brand.$secondary-color;
    background-color: brand.$primary-color;
    border-color: brand.$primary-color;
}

input {
    &[type="submit"].button-primary,
    &[type="reset"].button-primary,
    &[type="button"].button-primary {
        color: brand.$secondary-color;
        background-color: brand.$primary-color;
        border-color: brand.$primary-color;
    }
}

.button.button-primary:hover,
button.button-primary:hover {
    color: brand.$primary-color;
    background-color: brand.$accent-color;
    border-color: brand.$accent-color;
}

input {
    &[type="submit"].button-primary:hover,
    &[type="reset"].button-primary:hover,
    &[type="button"].button-primary:hover {
        color: brand.$primary-color;
        background-color: brand.$accent-color;
        border-color: brand.$accent-color;
    }
}

.button.button-primary:focus,
button.button-primary:focus {
    color: brand.$secondary-color;
    background-color: brand.$primary-color;
    border-color: brand.$accent-color;
}

input {
    &[type="submit"].button-primary:focus,
    &[type="reset"].button-primary:focus,
    &[type="button"].button-primary:focus {
        color: brand.$secondary-color;
        background-color: brand.$primary-color;
        border-color: brand.$accent-color;
    }

    &[type="email"],
    &[type="number"],
    &[type="search"],
    &[type="text"],
    &[type="tel"],
    &[type="url"],
    &[type="password"] {
        height: 38px;
        padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
        background-color: brand.$secondary-color;
        border: 1px solid lighten(brand.$border-color, 8.8%);
        border-radius: utilities.$global-radius;
        box-shadow: none;
        box-sizing: border-box;
    }
}
